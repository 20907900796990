<template>
  <div>
    <div>
      这是学生数据
      <div
        v-for="(item, index) in student"
        style="background: #5daf34; display: flex"
      >
        <div v-for="(t, i) in item" style="margin-left: 10px">{{ t }}</div>
      </div>
    </div>
    <div>
      这是老师数据
      <div
        v-for="(item, index) in teacher"
        style="background: #8cc5ff; display: flex"
      >
        <div v-for="(t, i) in item" style="margin-left: 10px">{{ t }}</div>
      </div>
    </div>
    <div>
      申诉熟读
      <div>
        {{ startData }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "demo",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      teacher: [],
      student: [],
      startData: [],
      appealValue: "",
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
    this.getShendu();
    this.getTeacher();
    this.getStudent();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    getTeacher() {
      this.$cloud
        .post("demo/demo", { role: "teacher" })
        .then((data) => {
          this.teacher = data;
        })
        .catch((error) => {});
    },
    getStudent() {
      this.$cloud
        .post("demo/test", { gender: 1 })
        .then((data) => {
          this.student = data;
        })
        .catch((error) => {});
    },
    getShendu() {
      this.$cloud
        .get("/statement/list_initiate", {
          read: "NO",
        })
        .then((res) => {
          this.startData = res?.list || [];
          this.appealValue = this.startData.length || 0;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>
<style scoped></style>
